import { React, useState, useEffect } from "react";
import { getUser, getTransaksi } from "../services";
import { NumericFormat } from 'react-number-format';
import { Link } from 'react-router-dom'


function Home() {
    const [user, setUser] = useState('')
    const [saldo, setSaldo] = useState('')
    const [transaksi, setTransaksi] = useState([])

    useEffect(() => {
        var token = localStorage.getItem('Token');

        console.log(token)

        if (!token) {
            window.location.href = '/';
        }

        setUser(JSON.parse(localStorage.getItem('user')));

        async function getSaldo() {
            var dataSaldo = await getUser();
            setSaldo(dataSaldo);
        }

        async function _getTransaksi() {
            var dataTransaksi = await getTransaksi();
            setTransaksi(dataTransaksi.data);
        }
        getSaldo();
        _getTransaksi();

    }, []);


    return (
        <>
            <div className="appHeader bg-primary text-light">
                {/* <div className="left">
                    <a href="#" className="headerButton" data-bs-toggle="modal" data-bs-target="#sidebarPanel">
                        <ion-icon name="menu-outline"></ion-icon>
                    </a>
                </div> */}
                <div className="pageTitle">

                </div>
                <div className="right">

                    <a href="/profile" className="headerButton">
                        <img src={saldo ? saldo.data.profile_photo_url : "assets/img/sample/avatar/avatar1.jpg"} alt="image" className="imaged w32" />
                    </a>
                </div>
            </div>

            <div id="appCapsule">

                <div className="section wallet-card-section pt-1">
                    <div className="wallet-card">

                        <div className="balance">
                            <div className="left">
                                <span className="title">Total Balance</span>
                                <h1 className="total">Rp.  <NumericFormat displayType="text" value={saldo ? saldo.data.saldo : 0} thousandsGroupStyle="lakh" thousandSeparator="," /></h1>
                            </div>
                            <div className="right">
                                {/* <a href="#" className="button" data-bs-toggle="modal" data-bs-target="">
                                    <ion-icon name="add-outline"></ion-icon>
                                </a> */}
                            </div>
                        </div>

                        <div className="wallet-footer">
                            <div className="item">
                                <a href="https://baksampahpapin.pangkalpinangkota.go.id/layanan" >
                                    <div className="icon-wrapper bg-danger">
                                        <ion-icon name="arrow-down-outline"></ion-icon>
                                    </div>
                                    <strong>Pelayanan Nasabah </strong>
                                </a>
                            </div>
                            <div className="item">
                                <a href="https://baksampahpapin.pangkalpinangkota.go.id/nabung" >
                                    <div className="icon-wrapper">
                                        <ion-icon name="arrow-forward-outline"></ion-icon>
                                    </div>
                                    <strong>Cara Menabung</strong>
                                </a>
                            </div>
                            <div className="item">
                                <a href="https://baksampahpapin.pangkalpinangkota.go.id/lokasi">
                                    <div className="icon-wrapper bg-success">
                                        <ion-icon name="card-outline"></ion-icon>
                                    </div>
                                    <strong>Lokasi Bank Sampah</strong>
                                </a>
                            </div>

                        </div>

                    </div>
                </div>


                <div className="modal fade action-sheet" id="withdrawActionSheet" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Withdraw Money</h5>
                            </div>
                            <div className="modal-body">
                                <div className="action-sheet-content">
                                    <form>
                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="label" for="account2d">From</label>
                                                <select className="form-control custom-select" id="account2d">
                                                    <option value="0">Savings (*** 5019)</option>
                                                    <option value="1">Investment (*** 6212)</option>
                                                    <option value="2">Mortgage (*** 5021)</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="label" for="text11d">To</label>
                                                <input type="email" className="form-control" id="text11d" placeholder="Enter IBAN" />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <label className="label">Enter Amount</label>
                                            <div className="input-group mb-2">
                                                <span className="input-group-text" id="basic-addonb1">$</span>
                                                <input type="text" className="form-control" placeholder="Enter an amount"
                                                    value="100" />
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <button type="button" className="btn btn-primary btn-block btn-lg"
                                                data-bs-dismiss="modal">Withdraw</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade action-sheet" id="sendActionSheet" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Send Money</h5>
                            </div>
                            <div className="modal-body">
                                <div className="action-sheet-content">
                                    <form>
                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="label" for="account2">From</label>
                                                <select className="form-control custom-select" id="account2">
                                                    <option value="0">Savings (*** 5019)</option>
                                                    <option value="1">Investment (*** 6212)</option>
                                                    <option value="2">Mortgage (*** 5021)</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="label" for="text11">To</label>
                                                <input type="email" className="form-control" id="text11"
                                                    placeholder="Enter bank ID" />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <label className="label">Enter Amount</label>
                                            <div className="input-group mb-2">
                                                <span className="input-group-text" id="basic-addon1">$</span>
                                                <input type="text" className="form-control" placeholder="Enter an amount"
                                                    value="100" />
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <button type="button" className="btn btn-primary btn-block btn-lg"
                                                data-bs-dismiss="modal">Send</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade action-sheet" id="exchangeActionSheet" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Exchange Money</h5>
                            </div>
                            <div className="modal-body">
                                <div className="action-sheet-content">
                                    <form>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form-group basic">
                                                    <div className="input-wrapper">
                                                        <label className="label" for="currency1">From</label>
                                                        <select className="form-control custom-select" id="currency1">
                                                            <option value="1">EUR</option>
                                                            <option value="2">USD</option>
                                                            <option value="3">AUD</option>
                                                            <option value="4">CAD</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group basic">
                                                    <div className="input-wrapper">
                                                        <label className="label" for="currency2">To</label>
                                                        <select className="form-control custom-select" id="currency2">
                                                            <option value="1">USD</option>
                                                            <option value="1">EUR</option>
                                                            <option value="2">AUD</option>
                                                            <option value="3">CAD</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <label className="label">Enter Amount</label>
                                            <div className="input-group mb-2">
                                                <span className="input-group-text" id="basic-addon2">$</span>
                                                <input type="text" className="form-control" placeholder="Enter an amount"
                                                    value="100" />
                                            </div>
                                        </div>



                                        <div className="form-group basic">
                                            <button type="button" className="btn btn-primary btn-block btn-lg"
                                                data-bs-dismiss="modal">Exchange</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="section mt-4">
                    <div className="section-heading">
                        <h2 className="title">Transactions</h2>
                        {/* <a href="/transaksi" className="link">View All</a> */}
                    </div>
                    <div className="transactions">
                        {transaksi.map((items) => {
                            return (
                                <Link key={items.id}
                                    to={{ pathname: `/transaksi-detail`, state: `${items.sampah ? items.sampah.namasampah : "NoName"}`, tanggal: `${items ? items.tanggal : "0000-00-00"}`, harga: `${transaksi ? items.debet : 0}`, gambar: `${items.sampah ? items.sampah.photo : "https://images.tokopedia.net/img/cache/1200/BgtCLw/2021/10/20/719a5eca-515f-4c78-ab89-dacec84e79ed.jpg?ect=4g/hTWP87X80jQrejhSgeQxKBnnPJFaaaDRq2CvVX9D.jpg"}`, userName: `${items ? items.user.name : "NoName"}` }} className="item">
                                    <div className="detail">
                                        <img src={items.sampah ? items.sampah.photo : "https://images.tokopedia.net/img/cache/1200/BgtCLw/2021/10/20/719a5eca-515f-4c78-ab89-dacec84e79ed.jpg?ect=4g/hTWP87X80jQrejhSgeQxKBnnPJFaaaDRq2CvVX9D.jpg"} alt="img" className="image-block imaged w48" />
                                        <div>
                                            <strong>{items.sampah ? items.sampah.namasampah : "NoName"}</strong>
                                            <p>{items.tanggal}</p>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <div className="price text-danger"> Rp. <NumericFormat displayType="text" value={transaksi ? items.debet : 0} thousandsGroupStyle="lakh" thousandSeparator="," /></div>
                                    </div>
                                </Link>
                            );
                        })}

                    </div>
                </div>


            </div>


        </>
    );
}

export default Home;
