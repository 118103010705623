import { NumericFormat } from 'react-number-format';

function Detail(props) {
    console.log(props.location)
    var token = localStorage.getItem('Token');

    if (!token) {
        window.location.href = '/';
    }
    return (
        <>
            <div className="appHeader">
                <div className="left">
                    <a href="#" className="headerButton goBack">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </a>
                </div>
                <div className="pageTitle">
                    Transaction Detail
                </div>
                <div className="right">
                    <a href="#" className="headerButton" data-bs-toggle="modal" data-bs-target="#DialogBasic">
                        <ion-icon name="trash-outline"></ion-icon>
                    </a>
                </div>
            </div>



            <div id="appCapsule" className="full-height">

                <div className="section mt-2 mb-2">


                    <div className="listed-detail mt-3">
                        <div className="icon-wrapper">

                            <div className="iconbox">
                                <img src={props.location.gambar} className="image-block imaged w32" />
                                <ion-icon name="arrow-forward-outline"></ion-icon>
                            </div>
                        </div>
                        <h3 className="text-center mt-2">Payment Sent</h3>
                    </div>

                    <ul className="listview flush transparent simple-listview no-space mt-3">
                        <li>
                            <strong>Status</strong>
                            <span className="text-success">Success</span>
                        </li>
                        <li>
                            <strong>To</strong>
                            <span>{props.location.userName}</span>
                        </li>
                        <li>
                            <strong>Nama Sampah</strong>
                            <span>{props.location.state}</span>
                        </li>
                        {/* <li>
                            <strong>Transaction Category</strong>
                            <span>Shopping</span>
                        </li> */}
                        <li>
                            <strong>Receipt</strong>
                            <span>Yes</span>
                        </li>
                        <li>
                            <strong>Date</strong>
                            <span>{props.location.tanggal}</span>
                        </li>
                        <li>
                            <strong>Amount</strong>
                            <h3 className="m-0">Rp. <NumericFormat displayType="text" value={props.location.harga} thousandsGroupStyle="lakh" thousandSeparator="," /></h3>
                        </li>
                    </ul>


                </div>

            </div>
        </>
    );
}

export default Detail;
