import React, { useState, useEffect } from "react";
import ModalDanger from "../component/ModalDanger";
import { login } from "../services";


function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorModal, setErrorModal] = useState('false')

  var token = localStorage.getItem('Token');

  if (!token) {
  } else {
    window.location.href = '/home';

  }


  const handleEmail = event => {
    setEmail(event.target.value);
  };

  const handlePassword = event => {
    setPassword(event.target.value);
  };

  async function _loginEmail(_email, _password) {
    var data = await login(_email, _password);

    if (data == 'false') {
      setErrorModal('true');
    } else {
      window.location.href = '/home';
      localStorage.setItem('Token', data.data.access_token);
      localStorage.setItem('user', JSON.stringify(data.data.user));
    }
  }

  return (
    <>
      <div className="appHeader no-border transparent position-absolute">
        <div className="left">
          <a href="#" className="headerButton goBack">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </a>
        </div>
        <div className="pageTitle"></div>
        <div className="right">
        </div>
      </div>

      <div id="appCapsule">

        <div className="section mt-2 text-center">
          <h1>Log in</h1>
          <h4>Fill the form to log in</h4>
        </div>
        <div className="section mb-5 p-2">


          <div className="card">
            <div className="card-body pb-1">
              <div className="form-group basic">
                <div className="input-wrapper">
                  <label className="label" for="email1">E-mail</label>
                  <input onChange={handleEmail} type="email" className="form-control" id="email1" placeholder="Your e-mail" />
                  <i className="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                  </i>
                </div>
              </div>

              <div className="form-group basic">
                <div className="input-wrapper">
                  <label className="label" for="password1">Password</label>
                  <input onChange={handlePassword} type="password" className="form-control" id="password1"
                    placeholder="Your password" />
                  <i className="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                  </i>
                </div>
              </div>
            </div>
          </div>


          <div className="form-links mt-2">
            <div>
              <a href="/register">Register Now</a>
            </div>
            {/* <div><a href="app-forgot-password.html" className="text-muted">Forgot Password?</a></div> */}
          </div>

          <div className="form-button-group  transparent">
            <button onClick={() => _loginEmail(email, password)} className="btn btn-primary btn-block btn-lg">Log in</button>
          </div>


        </div>

      </div>


      {errorModal === 'true' ? (
        <div id="notification-6" className="notification-box show tap-to-close">
          <div className="notification-dialog ios-style bg-danger">
            <div className="notification-header">
              <div className="in">
                <img src="assets/img/sample/avatar/avatar3.jpg" alt="image" className="imaged w24 rounded" />
                <strong>Bank Papin</strong>
              </div>
              <div className="right">

              </div>
            </div>
            <div className="notification-content">
              <div className="in">
                <h3 className="subtitle">Login Failed</h3>
                <div className="text">
                  Request failed with status code 500
                </div>
              </div>
            </div>
            <div className="notification-footer">
              <a href="/register" className="notification-button">
                <ion-icon name="wallet-outline"></ion-icon>
                Register
              </a>
              <a href="/" className="notification-button">
                <ion-icon name="card-outline"></ion-icon>
                Close
              </a>
            </div>
          </div>
        </div>
      ) :
        null
      }
    </>
  );
}

export default Login;
