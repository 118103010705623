import axios from 'axios';
import ModalDanger from '../component/ModalDanger';

export const login = async (email, password) => {

    let formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    var test = axios({
        method: "post",
        url: process.env.REACT_APP_PUBLIC_API_URL + '/login',
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {
            return "false";
        });

    return test;

}

export const getUser = async () => {

    var token = "Bearer " + localStorage.getItem('Token');
    var dataUser = axios({
        method: "get",
        url: process.env.REACT_APP_PUBLIC_API_URL + '/user',
        headers: { "Accept": "application/json", "Authorization": token },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {
            return response;
        });

    return dataUser;

}

export const getTransaksi = async () => {

    var token = "Bearer " + localStorage.getItem('Token');

    var dataTransaksi = axios({
        method: "get",
        url: process.env.REACT_APP_PUBLIC_API_URL + '/transactions',
        headers: { "Accept": "application/json", "Authorization": token },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {
            return response;
        });

    return dataTransaksi;

}

export const register = async (name, email, username, password, phone) => {

    let formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("username", username);
    formData.append("password", password);
    formData.append("phone", phone);

    var test = axios({
        method: "post",
        url: process.env.REACT_APP_PUBLIC_API_URL + '/register',
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {
            return "false";
        });

    return test;

}