function Transaction(props) {
    return (
        <>
            <div className="appHeader">
                <div className="left">
                    <a href="#" className="headerButton goBack">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </a>
                </div>
                <div className="pageTitle">
                    Transactions
                </div>
                <div className="right">
                    <a href="crypto-exchange.html" className="headerButton">
                        <ion-icon name="add"></ion-icon>
                    </a>
                </div>
            </div>

            <div id="appCapsule">

                <div className="section mt-2">
                    <div className="section-title">Today</div>
                    <div className="card">
                        <ul className="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">

                            <li>
                                <a href="#" className="item">
                                    <div className="icon-box bg-success">
                                        <ion-icon name="arrow-up-outline"></ion-icon>
                                    </div>
                                    <div className="in">
                                        <div>
                                            <strong>Bitcoin</strong>
                                            <div className="text-small text-secondary">Sell</div>
                                        </div>
                                        <div className="text-end">
                                            <strong>$855,24</strong>
                                            <div className="text-small">
                                                Today 11:38 AM
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="section mt-2">
                    <div className="section-title">This Week</div>
                    <div className="card">
                        <ul className="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">

                            <li>
                                <a href="#" className="item">
                                    <div className="icon-box bg-warning">
                                        <ion-icon name="arrow-down-outline"></ion-icon>
                                    </div>
                                    <div className="in">
                                        <div>
                                            <strong>Etherium</strong>
                                            <div className="text-small text-secondary">Buy</div>
                                        </div>
                                        <div className="text-end">
                                            <strong>$40,68</strong>
                                            <div className="text-small">
                                                March 8, 2021
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#" className="item">
                                    <div className="icon-box">
                                        <img src="assets/img/sample/avatar/avatar4.jpg" alt="image" className="imaged rounded w36" />
                                    </div>
                                    <div className="in">
                                        <div>
                                            <strong>Bitcoin</strong>
                                            <div className="text-small text-secondary">Send</div>
                                        </div>
                                        <div className="text-end">
                                            <strong>$100,00</strong>
                                            <div className="text-small">
                                                March 4, 2021
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#" className="item">
                                    <div className="icon-box bg-secondary">
                                        <ion-icon name="swap-vertical-outline"></ion-icon>
                                    </div>
                                    <div className="in">
                                        <div>
                                            <strong>Tether</strong>
                                            <div className="text-small text-secondary">Trade</div>
                                        </div>
                                        <div className="text-end">
                                            <strong>$2.405,48</strong>
                                            <div className="text-small">
                                                February 24, 2021
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="section mt-2">
                    <div className="section-title">December, 2021</div>
                    <div className="card">
                        <ul className="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">

                            <li>
                                <a href="#" className="item">
                                    <div className="icon-box">
                                        <img src="assets/img/sample/avatar/avatar5.jpg" alt="image" className="imaged rounded w36" />
                                    </div>
                                    <div className="in">
                                        <div>
                                            <strong>Etherium</strong>
                                            <div className="text-small text-secondary">Send</div>
                                        </div>
                                        <div className="text-end">
                                            <strong>$40,68</strong>
                                            <div className="text-small">
                                                December 22, 2021
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#" className="item">
                                    <div className="icon-box">
                                        <img src="assets/img/sample/avatar/avatar8.jpg" alt="image" className="imaged rounded w36" />
                                    </div>
                                    <div className="in">
                                        <div>
                                            <strong>Bitcoin</strong>
                                            <div className="text-small text-secondary">Send</div>
                                        </div>
                                        <div className="text-end">
                                            <strong>$100,00</strong>
                                            <div className="text-small">
                                                December 16, 2021
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#" className="item">
                                    <div className="icon-box bg-success">
                                        <ion-icon name="arrow-up-outline"></ion-icon>
                                    </div>
                                    <div className="in">
                                        <div>
                                            <strong>Bitcoin</strong>
                                            <div className="text-small text-secondary">Sell</div>
                                        </div>
                                        <div className="text-end">
                                            <strong>$855,24</strong>
                                            <div className="text-small">
                                                December 15, 2021
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#" className="item">
                                    <div className="icon-box bg-success">
                                        <ion-icon name="arrow-up-outline"></ion-icon>
                                    </div>
                                    <div className="in">
                                        <div>
                                            <strong>Cardano</strong>
                                            <div className="text-small text-secondary">Sell</div>
                                        </div>
                                        <div className="text-end">
                                            <strong>$1.625,24</strong>
                                            <div className="text-small">
                                                December 12, 2021
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#" className="item">
                                    <div className="icon-box bg-secondary">
                                        <ion-icon name="swap-vertical-outline"></ion-icon>
                                    </div>
                                    <div className="in">
                                        <div>
                                            <strong>Etherium</strong>
                                            <div className="text-small text-secondary">Trade</div>
                                        </div>
                                        <div className="text-end">
                                            <strong>$516,48</strong>
                                            <div className="text-small">
                                                December 8, 2021
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>


                <div className="section mt-3 mb-3">
                    <a href="#" className="btn btn-lg btn-block btn-primary">Load More</a>
                </div>


            </div>
        </>
    );
}

export default Transaction;
