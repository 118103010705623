import { BrowserRouter } from "react-router-dom";
import Pages from "./pages";
// import { Header, Footer } from "./components";
import { Component } from "react";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sign: ""
        }
    }

    render() {

        return (
            <BrowserRouter className="">
                <div className="text-base">
                    <Pages />
                </div>
            </BrowserRouter>
        );
    }
}

export default App;
