
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Login from './login'
import Home from './home'
import Setting from './setting'
import Transaction from './transaction'
import Detail from './detail'
import Register from './register'


export default function index(props) {
    return (
        <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/profile" component={Setting} />
            <Route exact path="/transaksi" component={Transaction} />
            <Route exact path="/transaksi-detail" component={Detail} />
        </Switch>
    )
}
