import { React, useState, useEffect } from "react";
import { getUser, getTransaksi } from "../services";

function Setting() {

    const [user, setSaldo] = useState('')

    useEffect(() => {
        var token = localStorage.getItem('Token');

        if (!token) {
            window.location.href = '/';
        }

        async function getSaldo() {
            var dataSaldo = await getUser();
            setSaldo(dataSaldo);
        }

        getSaldo();

    }, []);

    async function _logout() {
        localStorage.removeItem('Token');
        localStorage.removeItem('user');
        window.location.href = '/';

    }

    return (
        <>
            <div className="appHeader">
                <div className="left">
                    <a href="#" className="headerButton goBack">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </a>
                </div>
                <div className="pageTitle">
                    Settings
                </div>
                {/* <div className="right">
                    <a href="app-notifications.html" className="headerButton">
                        <ion-icon className="icon" name="notifications-outline"></ion-icon>

                    </a>
                </div> */}
            </div>

            <div id="appCapsule">

                <div className="section mt-3 text-center">
                    <div className="avatar-section">
                        <a href="#">
                            <img src={user ? user.data.profile_photo_url : "assets/img/sample/avatar/avatar1.jpg"} alt="avatar" className="imaged w100 rounded" />

                        </a>
                    </div>
                </div>


                <div className="listview-title mt-1">Profile Settings</div>
                <ul className="listview image-listview text inset">
                    <li>
                        <a href="#" className="item">
                            <div className="in">
                                <div>Username</div>
                                {user ? user.data.username : "NoName"}
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="item">
                            <div className="in">
                                <div>Name</div>
                                {user ? user.data.name : "NoName"}
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="item">
                            <div className="in">
                                <div>E-mail</div>
                                {user ? user.data.email : "NoEmail"}
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="item">
                            <div className="in">
                                <div>Phone</div>
                                {user ? user.data.phone : "No Number"}
                            </div>
                        </a>
                    </li>


                </ul>

                <div className="listview-title mt-1">Security</div>
                <ul className="listview image-listview text mb-2 inset">
                    <li>
                        <a href="#" className="item">
                            <div className="in">
                                <div>Update Password</div>
                            </div>
                        </a>
                    </li>

                    <li>
                        <a onClick={() => _logout()} className="item">
                            <div className="in">
                                <div>Log out</div>
                            </div>
                        </a>
                    </li>
                </ul>


            </div>
        </>
    );
}

export default Setting;
