import React, { useState, useEffect } from "react";
import ModalDanger from "../component/ModalDanger";
import { register } from "../services";


function Register() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [phone, setPhone] = useState('');

  // const [errorModal, setErrorModal] = useState('false')

  var token = localStorage.getItem('Token');

  if (!token) {
  } else {
    // window.location.href = '/home';

  }

  const handleName = event => {
    setName(event.target.value);
  };

  const handleEmail = event => {
    setEmail(event.target.value);
  };

  const handleUsername = event => {
    setUsername(event.target.value);
  };

  const handlePassword = event => {
    setPassword(event.target.value);
  };

  const handlePassword2 = event => {
    setPassword2(event.target.value);
  };

  const handlePhone = event => {
    setPhone(event.target.value);
  };

  async function _register(_name, _email, _username, _password, _phone) {

    if (password.length >= 8) {
      if (password == password2) {
        var data = await register(_name, _email, _username, _password, _phone);

        if (data == 'false') {
          // setErrorModal('true');
        } else {
          window.location.href = '/home';
          localStorage.setItem('Token', data.data.access_Token);
          localStorage.setItem('user', JSON.stringify(data.data.user));
        }
      } else {
        alert("passwords do not match");
      }
    } else {
      alert("passwords min 8 character");

    }



  }

  return (
    <>
      <div className="appHeader no-border transparent position-absolute">
        <div className="left">
          <a href="#" className="headerButton goBack">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </a>
        </div>
        <div className="pageTitle"></div>
        <div className="right">
          <a href="/" className="headerButton">
            Login
          </a>
        </div>
      </div>

      <div id="appCapsule">

        <div className="section mt-2 text-center">
          <h1>Register now</h1>
          <h4>Create an account</h4>
        </div>
        <div className="section mb-5 p-2">

          <div className="card">
            <div className="card-body">
              <div className="form-group basic">
                <div className="input-wrapper">
                  <label className="label" for="username">Username</label>
                  <input onChange={handleUsername} type="text" className="form-control" id="username" placeholder="Your Username" />
                  <i className="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                  </i>
                </div>
              </div>

              <div className="form-group basic">
                <div className="input-wrapper">
                  <label className="label" for="nama">Nama</label>
                  <input onChange={handleName} type="text" className="form-control" id="nama" placeholder="Your Name" />
                  <i className="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                  </i>
                </div>
              </div>

              <div className="form-group basic">
                <div className="input-wrapper">
                  <label className="label" for="email1">E-mail</label>
                  <input onChange={handleEmail} type="email" className="form-control" id="email1" placeholder="Your e-mail" />
                  <i className="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                  </i>
                </div>
              </div>

              <div className="form-group basic">
                <div className="input-wrapper">
                  <label className="label" for="phone">Phone</label>
                  <input onChange={handlePhone} type="number" className="form-control" id="phone" placeholder="Your Phone Number" />
                  <i className="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                  </i>
                </div>
              </div>

              <div className="form-group basic">
                <div className="input-wrapper">
                  <label className="label" for="password1">Password</label>
                  <input onChange={handlePassword} type="password" className="form-control" id="password1" autocomplete="off"
                    placeholder="Your password" />
                  <i className="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                  </i>
                </div>
              </div>

              <div className="form-group basic">
                <div className="input-wrapper">
                  <label className="label" for="password2">Password Again</label>
                  <input onChange={handlePassword2} type="password" className="form-control" id="password2" autocomplete="off"
                    placeholder="Type password again" />
                  <i className="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                  </i>
                </div>
              </div>

              {/* <div className="custom-control custom-checkbox mt-2 mb-1">
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="customCheckb1" />
                    <label className="form-check-label" for="customCheckb1">
                      I agree <a href="#" data-bs-toggle="modal" data-bs-target="#termsModal">terms and
                        conditions</a>
                    </label>
                  </div>
                </div> */}

            </div>
          </div>

          <div className="form-button-group transparent">
            <button onClick={() => _register(name, email, username, password, phone)} className="btn btn-primary btn-block btn-lg">Register</button>
          </div>

        </div>

      </div>
    </>
  );
}

export default Register;
